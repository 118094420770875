import React from "react"; 

function StaffBlock(props){
    return(
        <div className="StaffBlock">
                <img src={props.image} alt="staff"/>
                <h2>{props.name}</h2>
                <p>
                    {props.text}
                </p>
        </div>
    )
}

export default StaffBlock