import React from "react"; 

import StaffBlock from './staffBlock'
import piet from '../../../resources/piet.png'
import mieke from '../../../resources/mieke.png'
import wim from '../../../resources/mert100921005.jpg'

function StaffBlocks(){

    const textWim = "Tandprotheticus en eigenaar. Het is voor mij erg belangrijk dat ik direct met patiënten werk. Hierdoor kan ik goed inspelen op de wensen en mogelijkheden van de patiënt. Het vervaardigen en aanmeten van een vervangende gebitsprothese is in een hand. Met de samenwerkende tandartsen overleg ik dagelijks over de wensen van hun patiënten. Tandtechnicus sinds 1975 Tandtechnisch laboratoriumhouder sinds 1985. Tandprotheticus sinds 2008 De praktijk heeft contracten met de alle zorgverzekeraars De praktijk is ISO 9001 gecertificeerd" 
    const textMieke = "Mieke werkt sinds 1995 met mij samen, en doet alle voorkomende tandtechnische werkzaamheden. Reparaties, rebasen, uitwerken protheses, vervaardigen individuele lepels en gipswerkzaamheden. Tevens assisteert zij mij bij de patiënten behandelingen. Ze is zeer toegewijd en een geweldige uitstraling voor de praktijk. Ook vervangt zij collega Piet wanneer deze afwezig is. Mieke werkt 3 dagen per week in de praktijk."
    const textPiet = "Piet heeft jaren de bekendste bloemenzaak van Geldrop gehad en is mede daardoor goed geïnformeerd in het Gelderopse. Hij is nu met pensioen en werkt parttime in de praktijk. Piet bezoekt dagelijks de tandartspraktijken waar dental lab tandtechnische werkzaamheden voor verricht. Hij doet dit met veel enthousiasme en betrouwbaarheid."

    return(
        <div className="StaffBlocks">
            <h1>Het Team</h1>
            <div className="StaffBlocksContent">
                <StaffBlock name="Wim" text={textWim} image={wim}/>
                <StaffBlock name="Mieke" text={textMieke} image={mieke}/>
                <StaffBlock name="Piet" text={textPiet} image={piet}/>
            </div>
            
        </div>
    )
}

export default StaffBlocks