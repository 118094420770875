import Map from '../../resources/map.png'
function Contact(){
    return(
        <div>  
           <div class="ContactContent">
                <h1>
                    Contact
                </h1>
                <div class="ContactInfo">
                <p>
                    WH Mertens
                    Tandprotetische parkijk
                    <br></br><br></br>
                    Adres
                    Emopad 37A 
                    5663 PA Geldrop
                    Noord Brabant, Nederland
                    <br></br><br></br>
                    Telefoon:
                    040 286 1378
                    <br></br><br></br>
                    Mobiel:
                    06 5313 1335
                    <br></br><br></br>
                    Email:
                    info@whmertens.nl
                    <br></br><br></br>
                    
                </p>
                <img src={Map} alt="kaart" className="ContactMap"/>
                </div>


            </div>
        </div>
    )
}

export default Contact;