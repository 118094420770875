import React from "react" 
import logo from './../../../resources/logo_text.png'
import phone from './../../../resources/icons/phone.png'
import home from './../../../resources/icons/living-room.png'
// import wallet from './../../../resources/icons/wallet.png'
import contact from './../../../resources/icons/inbox.png'
import treatment from './../../../resources/icons/dentist.png'
import certificate from './../../../resources/icons/diploma.png'

import { Link, useLocation} from 'react-router-dom';


function Header(){
    const page=useLocation().pathname;
    return (
        <header>
                <div className="HeaderItems">
                    <div className="HeaderItem HeaderItemLeft">
                        <Link to="/">
                            <img src={logo} alt="Logo"/>
                        </Link>
                    </div>
                    

                    <div className="HeaderItem HeaderItemMiddle">
                        <nav>
                            <Link to="/">
                                <div className={page === "/" ? 'NavActive' : null} href="#">
                                    <img src={home} alt="Home"/>
                                    <p>Home</p>
                                </div>
                            </Link>

                            <Link to="/behandelingen">
                                <div className={page === "/behandelingen" ? 'NavActive' : null} href="#">
                                    <img src={treatment} alt="Treatment"/>
                                    <p>Behandelingen</p>
                                </div>
                            </Link>

                            {/* <Link to="/tarieven">
                                <div className={page === "/tarieven" ? 'NavActive' : null} href="#">
                                    <img src={wallet} alt="Wallet"/>
                                    <p>Tarieven</p>
                                </div>
                            </Link> */}

                            <Link to="/certificaties">
                                <div className={page === "/certificaties" ? 'NavActive' : null} href="#">
                                    <img src={certificate} alt="Certificate"/>
                                    <p>Certificaties</p>
                                </div>
                            </Link>

                            <Link to="/contact">
                                <div className={page === "/contact" ? 'NavActive' : null} href="#">
                                    <img src={contact} alt="Contact"/>
                                    <p>Contact</p>
                                </div>
                            </Link>
                        </nav>
                    </div>

                    <div className="HeaderItem HeaderItemRight">
                        <img src={phone} alt="Phone"/>
                        <h3>+316-5313-1335</h3>
                    </div>
                </div>
        </header>
    )
}

export default Header