import React from 'react'
import { Component } from 'react'

import inside from '../../resources/inside.png'

import TreatmentsBlock from './treatmentsBlock'
import TreatmentsInfo from './treatmentsInfo'

class Treatments extends Component{

    constructor(props){
        super(props);
        this.handleClick = this.handleClick.bind(this)
        this.getText = this.getText.bind(this)
        this.getTitle = this.getTitle.bind(this)
        this.state = {
            textIndex: 0,
            shouldSlide: false,
            slideUntill: 0
        }
    }

    handleClick(index){
        const element = document.getElementById("TreatmentsBlock")
        var rect = element.getBoundingClientRect();

        var newIndex = this.state.textIndex === index ? 0 : index;
        var newShouldSlide = this.state.textIndex === index ? false : true;
        this.setState({textIndex: newIndex, shouldSlide: newShouldSlide, slideUntill: rect.width});
    }

    getText(){
        return[
              "Tijdens de eerste zitting worden afdrukken gemaakt van de boven- en onderkaak. Dit gebeurt door middel van een fabrieksmatige metalen lepel, die ruim over de kaak past. Het afdrukmateriaal is vormvast, maar zacht en smaakt naar pepermunt. De afdrukken worden uitgegoten in gips."
              ,"De gipsmodellen van de boven- en onderkaak worden belijnt. De lijn wordt daar aangebracht waar het bot overgaat in de aangehechte spieren in de mond. Hierop wordt een individuele lepel vervaardigd. De lepel wordt in de mond geplaatst en afgedrukt volgens alle structuren in de mond. Dit is de basis voor een goede pasvorm van de prothese."
              ,"Bij de eerste beetbepaling wordt de positie van de boven elementen exact vastgelegd. Het midden, de positie van de elementen en de grootte. Tevens wordt de relatie tussen de boven-en onderkaak vastgelegd. De hoogte van de nieuwe gebitsprothese wordt zo bepaalt dat de maximale hoogte Ingesteld wordt met voldoende spreekruimte."
             ,"De tweede beetbepaling wordt uitgevoerd met ingebouwde apparatuur, volgens de fonetische beetbepaling. De beetbepaling wordt uitgevoerd door de pijlpunt registratie. De patient maakt voorwaartse en zijwaartse bewegingen die exact de positie van de onderkaak bepalen. Door middel van deze methode wordt de juiste beet vastgelegd. De kleur en de vorm van de elementen, tanden, wordt in overleg met de patiënt bepaalt."
             ,"De prothese wordt in deze zitting gepast in was. Alles wordt gecontroleerd. De kleur, vorm en grootte van de elementen. De juiste beet, de beethoogte en het gevoel van de patiënt hierbij. Als alles goed gekeurd is, kan de prothese naar de laatste behandelingen."
             ,"De prothese wordt nu van was omgezet in kunststof. Dit kan door middel van verschillende methodes. De was prothese wordt verwijderd en in kunststof gegoten. Deze wordt ingeslepen en uitgewerkt. Als laatste wordt hij op hoogglans gepolijst. De prothese wordt bij de patiënt in de mond geplaatst. De patient krijgt hierna instructies voor gebruik en onderhoud. De garantietermijn is wetterlijk vastgesled op 1 jaar."
        ]
    }

    getTitle(){
        return[
             "Eerste afdrukken boven- en onderkaak",
             "Individuele afdrukken boven-en onderkaak",
             "Fonetische beetbepaling",
             "Intra-orale beetbepaling",
             "Passen in was",
             "Afwerken prothese",
        ]        
    }

    render(){
        return(
            <div className="TreatmentsContent">
                <TreatmentsBlock handleOnClick={this.handleClick} active={this.state.textIndex}/>
                <TreatmentsInfo index={this.state.textIndex} text={this.getText()} title={this.getTitle()}/>
                <img src={inside} alt="person" className="TreatmentsBlockImage"></img>
                </div>
        )
    }
}

export default Treatments