import Wallet from '../../resources/icons/wallet_white.png'
import Button from '../../resources/icons/plus.png'
function PriceLinksBlock(){
    return(
        <div className="PriceBlock">
            <div className="PriceBlockHeader">
                    <h2>Tarieven</h2>
                    <img src={Wallet} alt="wallet" />
            </div>
            <div className="PriceList">
            <a href="https://www.w3schools.com/" target="_blank" rel="noreferrer">
                <div className="Treatment">
                    <h3>2020</h3>
                    <img src={Button} alt="button"/>
                </div>
            </a>
            <hr/>
            <a href="https://www.w3schools.com/" target="_blank" rel="noreferrer">
                <div className="Treatment">
                    <h3>2019</h3>
                    <img src={Button} alt="button"/>
                </div>
            </a>
            <hr/>
            <a href="https://www.w3schools.com/" target="_blank" rel="noreferrer">
                <div className="Treatment">
                    <h3>2018</h3>
                    <img src={Button} alt="button"/>
                </div>
            </a>
            <hr/>
            <a href="https://www.w3schools.com/" target="_blank" rel="noreferrer">
                <div className="Treatment">
                    <h3>2017</h3>
                    <img src={Button} alt="button"/>
                </div>
            </a>
            <hr/>
            </div>

        </div>
    )
}

export default PriceLinksBlock