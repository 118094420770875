import React from "react" 
import logo from './../../../resources/logo_no_text.png'

import { Link } from 'react-router-dom';

function Footer(){
    return (
        <footer>
            <div className="FooterItems">
                <div className="FooterHome">
                    <a href="/">
                        <img src={logo} alt="Logo"/>
                    </a>
                </div>
                
                <div className="FooterItem FooterItemLeft">
                    <div className="InnerFooterItem">
                        <h3>Contact</h3>
                        <p>
                            Emopad 37 A<br></br>
                            5663 PA Geldrop<br></br>
                                <br></br>
                            Mob: 06 5313 1335<br></br>
                            Tel: 040 286 1378<br></br>
                            Email: info@whmertens.nl<br></br>
                        </p>
                    </div>
                </div>

                <div className="FooterItem FooterItemMiddle">
                    <div className="InnerFooterItem">
                        <h3><Link to="/">Home</Link></h3>
                        <h3><Link to="/behandelingen">Behandelingen</Link></h3>
                        <h3><Link to="/certificaties">Certificaties</Link></h3>
                        <h3><Link to="/contact">Contact</Link></h3>
                    </div>
                </div>

                <div className="FooterItem FooterItemRight">
                    <div className="InnerFooterItem">
                        <p>
                        <Link to="/disclaimer">Privacy disclaimer</Link><br></br>
                        <br></br>
                        Copyright 2024<br></br>
                        W. H. Mertens Beheer B.V.<br></br>
                        All rights reserved<br></br>
                        <br></br>
                        Web design door Dani Mertens<br></br>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer