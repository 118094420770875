import React from 'react'
import { Component } from 'react'

import treatment from '../../resources/icons/dentist_white.png'
import button from '../../resources/icons/plus.png'
import activeButton from '../../resources/icons/min.png'

class TreatmentsBlock extends Component{

    constructor(props){
        super(props);
        this.onClick = this.onClick.bind(this)
    }

    onClick(index){
        this.props.handleOnClick(index)
    }

    render(){
        var active = this.props.active
        return(
            <div className="TreatmentsBlock" id="TreatmentsBlock">
                <div className="TreatmentsBlockHeader">
                    <h1>Behandelingen</h1>
                    <img src={treatment} alt="chair" />
                </div>
                <div className="TreatmentList">
                    <div className="Treatment"  onClick={() => this.onClick(0)}>
                        <h3>Eerste afdrukken boven- en onderkaak</h3>
                        <img src={active === 0 ? activeButton : button} alt="button"/>
                    </div>
                    <hr/>

                    <div className="Treatment" onClick={() => this.onClick(1)}>
                        <h3>Individuele afdrukken boven-en onderkaak</h3>
                        <img src={active === 1 ? activeButton : button} alt="button" />
                    </div>
                    <hr/>

                    <div className="Treatment" onClick={() => this.onClick(2)}>
                        <h3>Fonetische beetbepaling</h3>
                        <img src={active === 2 ? activeButton : button} alt="button" />
                    </div>
                    <hr/>

                    <div className="Treatment" onClick={() => this.onClick(3)}>
                        <h3>Intra-orale beetbepaling</h3>
                        <img src={active === 3 ? activeButton : button} alt="button" />
                    </div>
                    <hr/>

                    <div className="Treatment" onClick={() => this.onClick(4)}>
                        <h3>Passen in was</h3>
                        <img src={active === 4 ? activeButton : button} alt="button" />
                    </div>
                    <hr/>
                    <div className="Treatment" onClick={() => this.onClick(5)}>
                        <h3>Afwerken prothese</h3>
                        <img src={active === 5 ? activeButton : button} alt="button" />
                    </div>
                </div>

            
            </div>
        )
    }
}

export default TreatmentsBlock