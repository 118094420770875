import React from "react"
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";

import Header from './pages/standard/header'
import Footer from './pages/standard/footer'

import Home from './pages/home'
import Treatments from './pages/treatments'
import Prices from './pages/prices'
import Certificates from './pages/certificates'
import Contact from './pages/contact'
import Disclaimer from './pages/disclaimer'

function App(){
    return (
      <Router>
        <div>
            <div className="Content">
              <Header />

              <Switch>
                <Route path="/" exact component={Home}/>
                <Route path="/behandelingen" component={Treatments}/>
                <Route path="/tarieven" component={Prices}/>
                <Route path="/certificaties" component={Certificates}/>
                <Route path="/contact" component={Contact}/>
                <Route path="/disclaimer" component={Disclaimer}/>
              </Switch>

            </div>
          <Footer/>
        </div>
      </Router>
    )
}

export default App