import React from "react"; 

import Block from './block'

function FloatingBlock(){

    const text1 = "Hallo, ik ben Wim Mertens. In 1976 ben ik begonnen als tandtechnicus. Een mooi beroep, waar ik veel creativiteit in kwijt kan. De combinatie van werken en studeren trok mij erg aan. In 1985 ben ik samen met mijn toenmalige compagnon een eigen tandtechnisch laboratorium begonnen. In 2004 ben ik gaan studeren voor tandprotheticus. Ik wilde graag zelfstandig met patiënten werken. In 2008 ben ik afgestudeerd als tandprotheticus. Vanaf 2019 heb ik mijn eigen praktijk in Geldrop."
    const text2 = "Alles draait natuurlijk om kwaliteit. De juiste materialen en behandeltechnieken. Daar waar ik verbeteringen zie, laat ik digitalisering zijn intrede doen. Op welke onderdelen dit op dit moment nog niet het geval is, werk ik conventioneel. Vele persoonlijke wensen en technieken kunnen op dit moment nog niet vervangen worden door digitalisering. Dat producenten en leveranciers hier anders over denken, spreekt voor zich. Onze praktijk is gecertificeerd door Rooseboom consultants. Administratief en hygiënisch onder controle. Mede hier door en mijn studiepunten kan ik u de beste contracten met uw zorgverzekeraar aanbieden." 
    const text3 = "Het process begint met een kennismaking en intake. Tijdens de eerste afspraak worden alle persoonlijke gegevens ingevoerd en gecheckt. Eerst wordt een algemene anamnese afgenomen. Deze gegevens geven een duidelijk beeld over het verleden, aandachtpunten en verbeter punten. Wat kunnen we bereiken met de nieuwe prothese, of andere voorziening. Misschien is het plaatsen van implantaten noodzakelijk om het gewenste resultaat te bereiken. Alles gaat in volledig overleg met de patiënt, om samen tot het gewenste resultaat te komen. Er wordt tevens duidelijk uitgelegd wat de vergoedingen van de zorgverzekeraar zijn, om niet voor onverwachte uitgaven te komen." 
     return(
        <div className="FloatingBlocks">
            <Block className="BlockLeft Block" title="Ervaring" text={text1}/>
            <Block className="BlockMiddle Block" title="Qualiteit" text={text2}/>
            <Block className="BlockRight Block" title="Process" text={text3}/>
        </div>
    )
}

export default FloatingBlock