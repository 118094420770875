import React from "react"; 
import BannerBlock from './bannerBlock.js'
import FloatingBlocks from './landingBlocks/floatingBlocks'
import TextBlock from './textBlock'
import StaffBlocks from './staffBlocks/staffBlocks'

function Home(){
    const text = "Onze visie is om u een zo goed mogelijke vervanging te bieden van uw oorspronkelijke gebitselementen. Dit kan door een volledige prothese, een frame of gedeeltelijke gebitsprothese, een vastgeschoefde brug, of klikprothese op implantaten. U wordt door mij voor extractie van u eigen bestaande elementen, implantologie, voorbereidingen en aanvragen van machtigingen bergeleid en doorverwezen naar samenwerkende, eveneens gecertificeerde praktijken."
    return(
        <div>
            <BannerBlock/>
            <FloatingBlocks/>
            <TextBlock title="Visie" text={text}/>
            <StaffBlocks/>
        </div>
    )
}

export default Home