function Certificate(props){
    return(
        <div className="CertificateCard">
            <h2>{props.title}</h2>
            <img src={props.image} alt="certificaat"/>
            <p>
                {props.text}
            </p>
        </div>
    )
}

export default Certificate