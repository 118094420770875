import banner from '../../resources/building.png'


function BannerBlock(){
    return(
        <div className="BannerBlock">
            <div className="BannerBlockText">
                {/* <h1>Wim Mertens tandprotetische praktijk.</h1> */}
            </div>
            <img className="Banner" src={banner} alt="banner"/>
        </div>
    )
}

export default BannerBlock;