import React from 'react'
import TextBlock from '../home/textBlock'
import image from '../../resources/person_tmep.jpg'
import cert1 from '../../resources/cert1.jpg'
import cert2 from '../../resources/cert2.jpg'


import Certificate from './certificate'
function Certificates(){
    const text = "De praktijk heeft alle certificaten die mogelijk zijn"

    return(
        <div className="CertificatesContent">
            <TextBlock title="Certificaties" text={text}/>
            <Certificate image={cert1} text={"W.H. Mertens is tandprotheticus sinds 2008"} title={"tandprotheticus"}/>
            <Certificate image={cert2} text={"ISO 9001 certificate Roosenboom consults tot 2023"} title={"ISO 9001"}/>
            <Certificate image={image} text={"Hygiene certificate tot 2023"} title={"Hygiene certificate"}/>
        </div>
    )
}

export default Certificates