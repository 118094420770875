import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function TreatmentsInfo(props){
    return(
        <div className="TreatmentsInfo">
            <Carousel selectedItem={props.index} showArrows={false} showThumbs={false} showStatus={false} showIndicators={false}>
                <div className="HeightSetter">
                    <p className="legend"><span>{props.title[0]}</span> <br></br><br></br>{props.text[0]}</p>
                </div>
                <div>
                    <p className="legend"><span>{props.title[1]}</span> <br></br><br></br>{props.text[1]}</p>
                </div>
                <div>
                    <p className="legend"><span>{props.title[2]}</span> <br></br><br></br>{props.text[2]}</p>
                </div>
                <div>
                    <p className="legend"><span>{props.title[3]}</span> <br></br><br></br>{props.text[3]}</p>
                </div>
                <div>
                    <p className="legend"><span>{props.title[4]}</span> <br></br><br></br>{props.text[4]}</p>
                </div>
                <div>
                    <p className="legend"><span>{props.title[5]}</span> <br></br><br></br>{props.text[5]}</p>
                </div>
            </Carousel>
        </div>
    )
}

export default TreatmentsInfo