import React from "react" 
import TextBlock from '../home/textBlock.js'
import PriceLinksBlock from './priceLinksBlock.js'

function Prices(){
    const text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
    
    return(
        <div className="priceContent">
            <div className="priceInfoBlock">
                <TextBlock className="test" title="Tarieven" text={text + text}/>
                <TextBlock title="" text={text + text}/>
                <TextBlock title="" text={text + text}/>
            </div>

            <PriceLinksBlock/>
        </div>
    )
}

export default Prices