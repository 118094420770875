function TextBlock(props){
    return(
        <div className="TextBlock">
            <div className="TextBlockContent">
                <h1>
                    {props.title}
                </h1>
                <p>
                    {props.text} 
                </p>
            </div>
        </div>

    )
}

export default TextBlock